.container-table-cuenta {
  display: flex;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
}
.container-buttons-table-cuenta {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.container-table-cuenta-comps {
  width: 100%;
}
.btn-save-cuenta {
  background: #ff8144;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 0;
  border: none;
  border-radius: 2.5rem;
  width: 20%;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
  cursor: pointer;
}
@media only screen and (max-width: 620px) {
  .container-buttons-table-cuenta {
    flex-direction: column;
    align-items: center;
  }
  .btn-save-cuenta {
    width: 90%;
  }
}
