.main-container-cuenta-cobro {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container-cuenta-cobro {
  background-color: #fff;
  max-width: 90em;
  width: 85%;
  margin: auto;
  padding: 0 2em;
  border-radius: 10px;
  box-shadow: 0 5px 10px -5px rgb(0 0 0 / 30%);
  margin-top: 1em;
  margin-bottom: 2em;
  text-align: center;
}
.container-text-cuenta-cobro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  font-size: 1.5rem;
  font-family: "Roboto Mono", sans-serif;
  white-space: pre-line;
}
.sumary-text-cuenta-cobro {
  line-height: 50px;
  border-bottom: 3px solid #636262;
  padding-bottom: 20px;
}
.totalvalues-text-cuenta-cobro {
  line-height: 40px;
}
@media only screen and (max-width: 620px) {
  .container-cuenta-cobro {
    background-color: #fff;
    max-width: 90em;
    width: 98%;
    margin: auto;
    padding: 0;
    border-radius: 10px;
    box-shadow: 0 5px 10px -5px rgb(0 0 0 / 30%);
    margin-top: 2em;
    margin-bottom: 2em;
    text-align: center;
  }
}