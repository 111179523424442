.container-pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
.btn-pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-width: 1px;
    border-radius: 0.375rem;
    border-color: #a7a8aa;
    border-style: solid;
    margin: 0.25rem;
    cursor: pointer;
    font-size: 17px;
    
}
.btn-pagination:disabled{
    cursor: default;
}
.select-pagination{
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-width: 1px;
    border-radius: 0.375rem;
    background-color: transparent;
}