.card-container {
  display: flex;
  flex-direction: row;
  background-color: #ff8144;
  /* width: 100%; */
  box-shadow: 0px 0px 15px -5px;
  border-radius: 10px;
  /* margin-left: 3rem;
    margin-right: 3rem; */
  align-items: center;
  padding: 0.8em;
  /* margin-top: 3rem; */
  cursor: pointer;
}
.card-text {
  color: #fff;
  font-size: 2rem;
  margin-left: 1rem;
}

@media only screen and (max-width: 620px) {
  .card-text {
    color: #fff;
    font-size: 1.2rem;
    margin-left: 1rem;
  }
}
