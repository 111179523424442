.main-container-form-cuenta {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 90%;
  margin: auto;
  padding: 2em 2em;
  border-radius: 20px;
}
.container-form-cuenta {
  width: 90%;
  margin: auto;
  padding-bottom: 2em;
  border-radius: 20px;
  text-align: center;
  align-items: center;
}

.container-form-cuenta-withactions {
  width: 90%;
  margin: auto;
  padding-bottom: 2em;
  border-radius: 20px;
  text-align: center;
  display: grid;
  grid-template-columns: 5fr 1fr;
  gap: 3rem;
  align-items: center;
}
.container-fields-cuenta {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  align-items: center;
}
.container-buttons-cuenta {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}
.container-submit-buttons-cuenta {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  width: 50%;
}
.container-form-cuenta-ciudades {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}
.container-btn-peajes {
  width: 100%;
  margin: auto;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}
.form-group-select-cc {
  position: relative;
  --color: #5757577e;
  width: 102.5%;
}
.form-label-select {
  color: var(--color);
  position: absolute;
  top: 0;
  left: 5px;
  transform: translateY(10px);
  transition: transform 0.5s, color 0.3s;
  font-size: 1.4rem;
}
.form-input-select {
  width: 100%;
  background-color: none;
  color: #706c6c;
  font-size: 1rem;
  padding: 0.6em 0.6em;
  border: none;
  outline: none;
  border-bottom: 1px solid #5757577e;
}
.form_input_date {
  width: 100%;
  background-color: none;
  color: #706c6c;
  font-size: 1rem;
  padding: 0.6em 0.6em;
  border: none;
  outline: none;
  border-bottom: 1px solid #5757577e;
}
.form_input_date::placeholder {
  font-size: 1.4rem;
  color: #5757577e;
  font-family: "Roboto Mono", sans-serif;
}
.form-input-select:focus + .form-label-select,
.form-input-select:not(:placeholder-shown) + .form-label-select {
  outline: none;
  transform: translateY(-20px) scale(0.7);
  transform-origin: left top;
  color: #3866f2;
}
.btn_submit {
  background: #ff8144;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 0;
  border: none;
  border-radius: 2.5rem;
  cursor: pointer;
}
.container-form-cuenta-adicional {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}
.form-group-adicional {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ckeckbox-adicional {
  width: 20px;
  height: 20px;
  align-self: center;
  font-family: "Roboto Mono", sans-serif;
}
.rmdp-input {
  width: 100%;
  font-family: "Roboto Mono", sans-serif;
  padding-right: 50px;
}
.form-fields-grouped {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}
.form-registros-adicionales {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}
@media only screen and (max-width: 620px) {
  .container-form-cuenta {
    width: 90%;
    padding: 0.5em 0.5em;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  .container-submit-buttons-cuenta {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    width: 100%;
    padding: 2em 2em;
  }
}
