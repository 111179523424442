.modal-dark-background {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
}

.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.modal-rutas {
  width: 500px;
  height: 600px;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}
.modal-pacientes {
  width: 500px;
  height: 450px;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}
.modal-conductores {
  width: 500px;
  height: 680px;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  overflow-y: auto;
}
.modal-zonas {
  width: 600px;
  max-height: 680px;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  overflow-y: auto;
}
.modal-value-cuenta {
  width: 550px;
  height: 500px;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  overflow-y: auto;
}
.modal-change-password {
  width: 400px;
  height: 350px;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  overflow-y: auto;
}
.modal-peajes {
  width: 400px;
  height: 260px;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  overflow-y: auto;
}

.modal-confirmation {
  width: 500px;
  height: 270px;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  overflow-y: auto;
}

.modal-corte-cuenta {
  width: 40rem;
  max-width: 100vw;
  height: 23rem;
  background: white;
  color: white;
  z-index: 999;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  overflow-y: auto;
}

.modal-paciente-cuentas {
  max-width: 90vw;
  max-height: 95vh;
  /* height: 600px; */
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  overflow-y: auto;
}

.modal-header {
  position: sticky;
  top: 0;
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.modal-header-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modal-header-confirmation {
  height: 100px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.heading {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
}

.modalContent {
  display: grid;
  width: 90%;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-top: 2em;
  margin-bottom: 1em;
  padding: 20px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
}
.modal-paciente-repetido-container {
  max-width: 80vw;
  max-height: 70vh;
  /* height: 600px; */
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  overflow-y: auto;
}

.modal-paciente-repetido {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 50px;
  padding-left: 50px;
  max-height: 50%;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
}

.modalContent-confirmation {
  display: grid;
  width: 90%;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-top: 2em;
  margin-bottom: 1em;
  padding: 20px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
}
.modalActions {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.actionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: white;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: -7px;
  margin-right: -7px;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: translate(-4px, 4px);
}

.deleteBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.deleteBtn:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  transform: translateY(-5px);
  background: #ff3e4e;
}

.cancelBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}
.form-group-rutas {
  position: relative;
  --color: #5757577e;
  width: 100%;
}
.form-label-rutas {
  color: var(--color);
  position: absolute;
  top: 0;
  left: 5px;
  transform: translateY(1px);
  transition: transform 0.5s, color 0.3s;
  font-size: 1.4rem;
}
.form-select-rutas {
  width: 100%;
  background-color: none;
  color: #706c6c;
  font-size: 1rem;
  padding: 0.6em 0.6em;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--color);
}
.fields-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}
.form-select-rutas:focus + .form-label-rutas,
.form-select-rutas:not(:placeholder-shown) + .form-label-rutas {
  outline: none;
  transform: translateY(-20px) scale(0.7);
  transform-origin: left top;
  color: #3866f2;
}
.container-buttons-modal-conf {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}
.container-buttons-modal-dialisis {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
}
.btn-cancel {
  background: #fcfbfb;
  color: #3d3d3d;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 0;
  border: 1px solid #5757577e;
  border-radius: 1.5rem;
  cursor: pointer;
}
.btn-accept {
  background: #ff8144;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 0;
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
}
.btn-submit-center {
  background: #ff8144;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 0;
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
  width: 40%;
  align-self: center;
}
.btn-yes {
  background: #8bc34a;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 0;
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
}
.btn-no {
  background: #ff5722;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 0;
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
}
@media only screen and (max-width: 620px) {
  .modal-rutas {
    width: 300px;
  }
  .modal-pacientes {
    width: 400px;
  }
  .modal-conductores {
    width: 400px;
  }
  .modal-zonas {
    width: 300px;
  }
  .modal-change-password {
    width: 300px;
  }
}
