.container-form-conductor {
  display: grid;
  width: 90%;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-top: 2em;
  margin-bottom: 1em;
  padding: 20px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
}
.form-group-select-cc {
  position: relative;
  --color: #5757577e;
  width: 100%;
}
.form-input-select {
  width: 100%;
  background-color: none;
  color: #706c6c;
  font-size: 1rem;
  padding: 0.6em 0.6em;
  border: none;
  outline: none;
  border-bottom: 1px solid #5757577e;
}
