.main-container-edit-conductor {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container-edit-conductor {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  background-color: #fff;
  max-width: 700px;
  width: 90%;
  margin: auto;
  padding: 2em 2em;
  border-radius: 20px;
  box-shadow: 0 5px 10px -5px rgb(0 0 0 / 30%);
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: center;
}
