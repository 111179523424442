.container-cuentas {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.container-view-table-cuenta {
  display: flex;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
  width: 98%;
  overflow-x: auto;
}
.container-filter-cuentas {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.filter-cuentas-comps {
  display: grid;
  grid-template-columns: 90% 10%;
  gap: 1.5rem;
  width: 30%;
  margin-top: 1em;
  margin-bottom: 0.5em;
}
.btn-filter {
  background: #ff8144;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 0;
  border: none;
  border-radius: 2.5rem;
  width: 20%;
}
.btn-valor-total {
  background: #ff8144;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 0;
  border: none;
  border-radius: 2.5rem;
  width: 80%;
  margin-right: 10px;
  cursor: pointer;
}
.btn-load-cuentas {
  background: #ff8144;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 0rem;
  border: none;
  border-radius: 2.5rem;
  width: 15rem;
  margin-right: 10px;
  cursor: pointer;
  text-align: center;
  font-family: "Roboto Mono", sans-serif;
}

@media only screen and (max-width: 620px) {
  .container-filter-cuentas {
    display: grid;
    grid-template-columns: 90% 10%;
    gap: 1.5rem;
    width: 80%;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
}
