.container-users {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .container-users-table {
    display: flex;
    align-items: center;
    margin-top: 2em;
    margin-bottom: 2em;
    width: 98%;
    overflow-x: auto;
  }