.main-container-register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main_cond {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  background-color: #fff;
  max-width: 600px;
  width: 90%;
  margin: auto;
  padding: 2em 2em;
  border-radius: 20px;
  box-shadow: 0 5px 10px -5px rgb(0 0 0 / 30%);
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: center;
}

.form_group {
  position: relative;
  --color: #5757577e;
}
.form_label {
  color: var(--color);
  position: absolute;
  top: 0;
  left: 5px;
  transform: translateY(1px);
  transition: transform 0.5s, color 0.3s;
  font-size: 1.4rem;
}

.form_input {
  width: 90%;
  background-color: none;
  color: #706c6c;
  font-size: 1rem;
  padding: 0.6em 0.6em;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--color);
}

.form_input:focus + .form_label,
.form_input:not(:placeholder-shown) + .form_label {
  outline: none;
  transform: translateY(-20px) scale(0.7);
  transform-origin: left top;
  color: #3866f2;
}
.btn_submit {
  background: #ff8144;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 0;
  border: none;
  border-radius: 2.5rem;
  cursor: pointer;
}
.form-group-select-cc {
  position: relative;
  --color: #5757577e;
  width: 100%;
}
.form-label-select {
  color: var(--color);
  position: absolute;
  top: 0;
  left: 5px;
  transform: translateY(10px);
  transition: transform 0.5s, color 0.3s;
  font-size: 1.4rem;
}
.form-input-select {
  width: 100%;
  background-color: none;
  color: #706c6c;
  font-size: 1rem;
  padding: 0.6em 0.6em;
  border: none;
  outline: none;
  border-bottom: 1px solid #5757577e;
}
