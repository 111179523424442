.container-conductores {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container-conductores-table {
  display: flex;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
  width: 98%;
  overflow-x: auto;
}
.container-buttons-conductores {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 98%;
  margin-top: 2em;
}
.btn-add-conductor {
  background: #ff8144;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 0;
  border: none;
  border-radius: 2.5rem;
  width: 15%;
  margin-right: 10px;
  cursor: pointer;
  text-align: center;
  font-family: "Roboto Mono", sans-serif;
}
