.autocomplete-pacientes-container {
  display: block;
  border-radius: 7px;
  position: relative;
  /* transition: background 0.3s ease; */
}
.form-autocomplete-pacientes {
  position: relative;
  --color: #5757577e;
  width: 102.5%;
}

.pacientes-input {
  width: 100%;
  background-color: none;
  color: #706c6c;
  font-size: 1rem;
  padding: 0.6em 0.6em;
  border: none;
  outline: none;
  border-bottom: 1px solid #5757577e;
}

.form-label-pacientes {
  color: #5757577e;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 5px;
  transform: translateY(1px);
  transition: transform 0.5s, color 0.3s;
  font-size:1.4rem;
  font-family: "Roboto Mono", sans-serif;
}

.pacientes-suggestions {
  position: absolute;
  top: 40px;
  left: 0px;
  z-index: 999;
  cursor: pointer;
  width: 100%;
  max-height: 200px;
  overflow: auto;
}

.pacientes-suggestions > div {
  padding: 15px 25px;
  border-bottom: 1px solid #5757577e;
  transition: all 0.1s ease;
}

.pacientes-suggestions > div:hover {
  background-color: #dbdbdb !important;
  color: #000000;
}

.pacientes-suggestions > div,
.autocomplete-pacientes-input input {
  font-size: 1rem !important;
  font-weight: 700;
  background-color: #bcbab9d8;
  color: #3b3b3b;
  border-radius: 0.2rem;
}
.pacientes-input:focus + .form-label-pacientes,
.pacientes-input:not(:placeholder-shown) + .form-label-pacientes {
  /* border: 3px solid #0075FF; */
  outline: none;
  /* box-shadow: 3px 0px 30px rgba(163,163,163, 0.4); */
  transform: translateY(-20px) scale(0.7);
  transform-origin: left top;
  color: #3866f2;
}
