.home-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 100vh;
}
.home-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.home-card-container-admin {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  width: 60vw;
  justify-content: center;
  /* flex-direction: column;*/
}
.home-card-container-conductor {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
  width: 30%;
}
.home-card-container-revisor {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
  width: 30%;
}
.btn-logout {
  border: none;
  background: #ff8144;
  cursor: pointer;
  /* position: absolute; */
  /* align-self: flex-end; */
  /* margin-right: 15px;
  margin-top: 15px; */
  border-radius: 10px;
}
.icon-btn-logout {
  font-size: 50px;
}
.icon-btn-cards {
  font-size: 70px;
}

@media only screen and (max-width: 620px) {
  .home-card-container-admin {
    display: flex;
    flex-direction: column;
    width: 75vw;
    height: 100vh;
    gap: 2rem;
     /* justify-content: space-around; */
    /* padding-top: 10px;
    padding-bottom: 10px; */
  }
  .home-card-container-conductor {
    display: flex;
    flex-direction: column;
    width: 70vw;
    height: 100vh;
    gap: 2rem;
    justify-content: center;
  }
  .btn-logout {
    margin-right: 5px;
    margin-top: 5px;
  }

  .icon-btn-logout {
    font-size: 30px;
  }
  .icon-btn-cards {
    font-size: 30px;
  }
}
