.rutas-container-page {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.container-rutas-table {
  display: flex;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
  min-width: 85%;
  max-width: 95%;
  overflow-x: auto;
}
.container-buttons-rutas {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 95%;
  margin-top: 10px;
}
.btn-add-zona {
  background: #ff8144;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 0;
  border: none;
  white-space: nowrap;
  border-radius: 2.5rem;
  width: 15%;
  margin-right: 10px;
  cursor: pointer;
  text-align: center;
  font-family: "Roboto Mono", sans-serif;
}
.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  /* width: 100px; */

  color: white;
  background-color: #192733;
  padding: 5px;
  border-radius: 6px;
}
.top {
  top: -150%;
  left: 0%;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
}
.hover-text {
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
}
.tooltip-text::after {
  content: "";
  position: absolute;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.top::after {
  top: 100%;
  left: 15%;
}

#upload-rutas {
  display: none;
}
#update-rutas {
  display: none;
}
