.form_group-area {
  position: relative;
}
.form-input-area {
  width: 90%;
  background-color: none;
  color: #706c6c;
  font-size: 1rem;
  padding: 0.6em 0.6em;
  border: 1px solid #5757577e;
  border-radius: 7px;
  font-family: "Roboto Mono", sans-serif;

  /* border: none; */
  /* outline: none; */
  /* border-bottom: 1px solid var(--color); */
}
/* .form_input {
  width: 100%;
  background-color: none;
  color: #706c6c;
  font-size: 1rem;
  padding: 0.6em 0.6em;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--color);
}
.form-input-modal {
  width: 90%;
  color: #706c6c;
  font-size: 1rem;
  padding: 0.6em 0.6em;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--color);
}

.form_input:focus + .form_label,
.form_input:not(:placeholder-shown) + .form_label {
  outline: none;
  transform: translateY(-12px) scale(0.7);
  transform-origin: left top;
  color: #3866f2;
}
.form-input-modal:focus + .form_label,
.form-input-modal:not(:placeholder-shown) + .form_label {
  outline: none;
  transform: translateY(-12px) scale(0.7);
  transform-origin: left top;
  color: #3866f2;
}
input[type="date"] {
  font-family: "Roboto Mono", sans-serif;
} */
