.main-table{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
table {
  /* width: 80em; */
  table-layout: fixed;
  width: 100%;
  /* height: 800px; */
  /* 
  
 */
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-collapse: collapse;
  overflow: hidden;
  /* z-index: -1; */
  /* border: 3px solid rgb(26, 189, 201); */
}
/* tbody {
    border-bottom: 3px solid rgb(184, 23, 23);
    border-right: 3px solid rgb(184, 23, 23);
  }
tr:nth-child(1) { 
    border-top: 3px solid rgb(24, 26, 180); 
    border-bottom: 3px solid rgb(14, 185, 80);
    
  } */
tbody {
  z-index: -1;
}
th {
  /* border-bottom: 3px solid rgb(241, 72, 157); */
  /* border-right: 3px solid rgb(199, 164, 6); */
  color: #fff;
  padding: 15px;
}
td {
  padding: 15px;
  background-color: #fff;
  color: #000000;

  /* border-right: 3px solid rgb(0, 0, 0); */
}
th {
  text-align: center;
}
thead th {
  background-color: #ff8144;
}
tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
tbody td {
  position: relative;
  text-align: center;
  align-content: center;
}
tbody td:hover:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -9999px;
  bottom: -9999px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
}
.input-filter-table {
  background-color: #ff8144;
  color: white;
  border: none;
  outline: none;
  border-bottom: 1px solid white;
  cursor: pointer;
  width: 98%;
}
.form_input:focus {
  box-shadow: 20px 20px 20px 20 rgba(184, 26, 26, 0.992);
}
.cuenta-cobro-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: .5rem;
  width: 50%;
}
.cuenta-cobro-info-text {
  text-align: left;
}