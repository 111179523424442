.main-login-container {
  display: flex;
  height: 100vh;
}
.login-container {
  display: grid;
  background-color: #fff;
  max-width: 400px;
  width: 90%;
  margin: auto;
  padding: 2em 2em;
  border-radius: 20px;
  box-shadow: 0 5px 10px -5px rgb(0 0 0 / 30%);
  text-align: center;
}
.login-form-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.5rem;
  margin-top: 2em;
  margin-bottom: 1em;
}

.btn-submit {
  background: #ff8144;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 0;
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
}
