.form-group {
  position: relative;
  --color: #5757577e;
}
.form-label {
  color: var(--color);
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 5px;
  transform: translateY(1px);
  transition: transform 0.5s, color 0.3s;
  font-size: 1.2rem;
  font-family: "Roboto Mono", sans-serif;
}
.form-input {
  width: 100%;
  background-color: none;
  color: #706c6c;
  font-size: 1rem;
  padding: 0.6em 0.6em;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--color);
}
.form-input-modal {
  width: 90%;
  color: #706c6c;
  font-size: 1rem;
  padding: 0.6em 0.6em;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--color);
}

.form-input:focus + .form-label,
.form-input:not(:placeholder-shown) + .form-label {
  outline: none;
  transform: translateY(-20px) scale(0.7);
  transform-origin: left top;
  color: #3866f2;
}
.form-input-modal:focus + .formabel,
.form-input-modal:not(:placeholder-shown) + .form-label {
  outline: none;
  transform: translateY(-14px) scale(0.7);
  transform-origin: left top;
  color: #3866f2;
}
input[type="date"] {
  font-family: "Roboto Mono", sans-serif;
  width: 100%;
}
@media only screen and (max-width: 620px) {
  input[type="date"] {
    width: 90%;
  }
  .form-input {
    width: 90%;
  }
}
