.btn-icon {
  border: none;
  background: none;
  cursor: pointer;
  margin: 0;
  padding: 7;
}
.btn-icon-disabled {
  border: none;
  background: none;
  cursor: not-allowed;
  margin: 0;
  padding: 7;
}
